<template>
	<form @submit.prevent="tryLogin">
		<div>
			<input type="text" v-model="user">
		</div>
		<div>
			<input type="password" v-model="pass">
		</div>
		<div>
			<input type="submit">
		</div>
	</form>
</template>

<script>
	export default {
		data() {
			return {
				user: '',
				pass: ''
			};
		},
		methods: {
			async tryLogin() {
				let res = await this.post('Auth.login', [this.user, this.pass]);
				if (res === true && this.$route.query.return) {
					let url = this.$route.query.return;
					if (/^\/[a-z]/.test(url)) {
						location.href = url;
					}
				}
				this.$emit('change');
			}
		}
	};
</script>