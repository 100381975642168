<template>
	<div class="head-cell">
		<span class="head-cell-link" @click="onClick">
			<slot></slot>
		</span>
		<template>
			<span v-if="dir === 1" class="head-cell-icon zmdi zmdi-triangle-up"></span>
			<span v-else-if="dir === -1" class="head-cell-icon zmdi zmdi-triangle-down"></span>
		</template>
	</div>
</template>

<script>
	export default {
		props: ['sort'],
		computed: {
			absPropSort() {
				if (!this.sort) return '';
				return this.sort.replace(/^-/, '');
			},
			dir() {
				if (!this.app.query.sort) return 0;

				let absSort = this.app.query.sort.replace(/^-/, '');
				if (absSort !== this.absPropSort) return 0;

				return (this.app.query.sort[0] === '-' ? -1 : 1);
			}
		},
		methods: {
			onClick() {
				let sort = this.getNewSort();
				let query = { ...this.app.query, sort };
				this.navigate({ query });
			},
			getNewSort() {
				if (!this.sort) return;

				if (this.app.query.sort === this.absPropSort) {
					return '-' + this.absPropSort;
				}
				if (this.app.query.sort === '-' + this.absPropSort) {
					return this.absPropSort;
				}

				return this.sort;
			}
		}
	}
</script>

<style lang="less">
	.head-cell {
		white-space: nowrap;

		.head-cell-link {
			cursor: pointer;
		}
		.head-cell-icon {
			font-size: 9px;
			margin: 0 2px;
		}
	}
</style>