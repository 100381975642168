import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/Home.vue';
import Tours from './views/Tours.vue';
import Requests from './views/Requests.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/tours',
		name: 'Tours',
		component: Tours
	},
	{
		path: '/requests',
		name: 'Requests',
		component: Requests
	},
	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
	}
];

const router = new VueRouter({
	mode: 'history',
	base: '/',
	routes
});

export default router;
