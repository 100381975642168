<template>
	<div id="header">
		<div class="header-left">
			<template v-for="link in links">
				<router-link
					v-if="checkPageVisibility(link)"
					:to="link.url"
				>{{ link.label }}</router-link>
			</template>
		</div>
		<div class="header-right">
			<span class="logout-btn" @click="logout">Logout</span>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				links: [
					{label: 'Туры', url: '/tours'},
					{label: 'Заявки', url: '/requests'},
					{label: 'Лиды', url: '/leads'},
				]
			};
		},
		methods: {
			async logout() {
				let res = await this.post('Auth.logout');
				this.app.refreshUser();
			},
			checkPageVisibility(link) {
				let page = link.url.slice(1);
				return this.checkPermission('page:' + page);
			}
		}
	};
</script>

<style lang="less">
	@height: 40px;

	#header {
		height: @height;
		background: #0048a7;

		.header-left {
			float: left;
			padding: 0 0 0 10px;

			a {
				display: inline-block;
				padding: 0 10px;
				color: #fff;
				text-decoration: none;
				font-size: 14px;
				line-height: @height;

				&:hover {
					background: #0073dd;
				}
			}
		}
		.header-right {
			float: right;
		}
		.logout-btn {
			cursor: pointer;
		}
	}
</style>