import Vue from 'vue';
import App from './App.vue';
import components from './components';
import router from './router';
import mixin from './mixin';

Vue.config.productionTip = false;

Vue.mixin(mixin);

for (let tag in components) {
	Vue.component(tag, components[tag]);
}

new Vue({
	router,
	render: h => h(App)
}).$mount('#app');
