<template>
	<div v-if="inited" id="app">
		<auth-form v-if="user === false" @change="refreshUser"></auth-form>
		<template v-else-if="user">
			<the-header></the-header>
			<router-view/>
		</template>
	</div>
</template>

<script>
import 'fontawesome-4.7';
import 'material-design-iconic-font/dist/css/material-design-iconic-font.css';
import './styles/main.less';

let config;

export default {
	name: 'App',
	data() {
		return {
			inited: false,

			query: {},
			params: {},
			hash: null,
			routeName: null,

			user: null
		};
	},
	computed: {
		config() {
			return config;
		}
	},
	methods: {
		async initApp() {
			let data = await this.get('General.init');
			config = data.config;
			this.inited = true;
		},
		refreshRoute() {
			this.query = { ...this.$route.query };
			this.params = { ...this.$route.params };
			this.hash = this.$route.hash;
			this.routeName = this.$route.name;
		},
		async refreshUser() {
			this.user = await this.get('Auth.getMe');
		}
	},
	watch: {
		$route() {
			this.refreshRoute();
		}
	},
	async mounted() {
		await this.initApp();
		this.refreshRoute();
		this.refreshUser();
	}
}
</script>

<style lang="less">
#app {
	font-family: "Open Sans";
	font-size: 13px;
	padding: 0 0 100px;

	a {
		text-decoration: none;
		color: #4c78ab;

		&:hover {
			text-decoration: underline;
		}
		&:visited {
			color: #bd00ff;
		}
	}
}
</style>
