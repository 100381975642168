<template>
	<div class="article-editor" :data-mode="mode">
		<div class="mode-toggle">
			<span
				:class="mode === 'edit' ? 'mode-active' : ''"
				@click="mode = 'edit'"
			>Редактирование</span>
			<span
				:class="mode === 'preview' ? 'mode-active' : ''"
				@click="mode = 'preview'"
			>Предпросмотр</span>
		</div>
		<div v-if="mode === 'edit'" class="edit-block">
			<textarea v-model="code" @blur="emitChange"></textarea>
		</div>
		<div
			v-else-if="mode === 'preview'"
			v-html="html"
			class="preview-block"
		></div>
	</div>
</template>

<script>
	import { marked } from 'marked';

	export default {
		props: ['value'],
		data() {
			return {
				intv: null,
				code: '',
				mode: 'edit',
				html: ''
			};
		},
		methods: {
			emitChange() {
				this.$emit('change', this.code);
				this.$emit('input', this.code);
			}
		},
		watch: {
			mode() {
				if (this.mode === 'preview') {
					this.html = marked(this.code);
				}
			}
		},
		mounted() {
			this.intv = setInterval(this.emitChange, 1000);
			this.code = this.value;
		},
		destroyed() {
			if (this.intv !== null) {
				clearInterval(this.intv);
			}
		}
	}
</script>

<style lang="less">
	.article-editor {
		background: #fff;

		.mode-toggle {
			padding: 5px 0;

			& > span {
				display: inline-block;
				padding: 5px 10px;
				cursor: pointer;
				border: 1px solid transparent;

				&:hover, &.mode-active {
					border: 1px solid #ccc;
					background: #eee;
				}
			}
		}

		.edit-block, .preview-block {
			height: 300px;
			border: 1px solid #ccc;
		}
		.edit-block {
			padding: 5px 0;
		}
		.preview-block {
			padding: 5px 10px;
			overflow: auto;
		}

		textarea {
			padding: 5px 10px;
			width: 100%;
			height: 100%;
			border: none;
			outline: none;
		}
	}
</style>