import TheHeader from './components/TheHeader.vue';
import AuthForm from './components/AuthForm.vue';
import PageTable from './components/PageTable.vue';
import PageConsole from './components/PageConsole.vue';
import HeadCell from './components/HeadCell.vue';
import TourEditor from './components/TourEditor.vue';
import ArticleEditor from './components/ArticleEditor.vue';
import MultiSel from './components/MultiSel.vue';

export default {
	'the-header': TheHeader,
	'auth-form': AuthForm,
	'page-table': PageTable,
	'page-controls': PageConsole,
	'head-cell': HeadCell,
	'tour-editor': TourEditor,
	'article-editor': ArticleEditor,
	'multi-sel': MultiSel,
};