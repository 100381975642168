export default {
	props: [
		'query',
		'remoteMethod',
		'remoteStatMethod',
		'exportMethod',
		'beforeExportFn',
		'fieldMap',
		'hiddenFields',
		'noSortFields',
		'localControl',
		'portionSize',
		'filterFn',
		'noSort',
		'transformRowFn',
		'width',
		'reloadIgnoreFields',
		'smallHeader',
		'stat-fn',
		'defLocSortField',
		'loadLocAll',
		'requestInterceptor',
		'noTransformCells'
	],
	data() {
		return {
			statList: null,
			totalCnt: null,
			res: null,
			stat: null,
			rows: null,
			isLoading: false,
			isLoadingMore: false,
			isNoMore: false,
			portion: 0,
			loadTime: null,
			defaultPortionSize: 50,
			localShownCnt: 0,
			reloadParam: null,
			isExporting: false,
			lastGetStatRequest: 0
		};
	},
	computed: {
		params() {
			return this.query || this.app.query;
		},
		fields() {
			if (!this.rows || !this.rows[0]) return null;

			return Object.keys(this.rows[0]).filter(key => this.hiddenFieldsArr.indexOf(key) === -1);
		},
		hiddenFieldsArr() {
			if (!this.hiddenFields) return [];
			return this.hiddenFields.split(',');
		},
		noSortFieldsArr() {
			if (!this.noSortFields) return [];
			return this.noSortFields.split(',');
		},
		handledRows() {
			if (!this.rows) return null;
			if (!this.localControl) return this.rows;

			let rows = this.rows;
			let sort = this.params.sort || this.defLocSortField;
			if (sort) {
				let key = sort;
				if (sort.startsWith('-'))
					key = sort.substr(1);
				let dir = sort.startsWith('-') ? 1 : -1;
				rows = rows.sort((a, b) => {
					if (a[key] === null) return dir;
					if (b[key] === null) return -dir;
					if (a[key] < b[key]) return dir;
					if (a[key] > b[key]) return -dir;
					return 0;
				});
			}

			if (this.filterFn) {
				rows = rows.filter(this.filterFn);
			}

			this.totalCnt = rows.length;
			if(!this.loadLocAll)
				rows = rows.slice(0, this.localShownCnt);

			return rows;
		},
		tblBoxStyle() {
			if (!this.width) return null;
			let w = isNaN(this.width) ? this.width : (this.width + 'px');
			return {minWidth: w};
		}
	},
	methods: {
		async getRows(opts = {}) {
			if (this.isLoading) return;

			if (opts.isAppend) {
				if (this.isNoMore) return;

				this.portion++;
				this.isLoadingMore = true;
			} else {
				this.portion = 1;
				this.isNoMore = false;
				this.loadTime = null;
				//this.setLoading(true);

				this.tryLoadStat();
			}

			this.isLoading = true;
			let portion = this.portion;

			this.res = null;
			let res = await this.get(this.remoteMethod, [this.params, this.portion, opts.extraData], {full: true});

			this.isLoading = false;
			this.isLoadingMore = false;
			//this.setLoading(false);

			if (res.data.err) {
				this.$emit('error', this.getRemoteCallError(res));
				this.rows = [];
				return;
			}

			this.$emit('load', res.data.res, portion);

			let rows = res.data.res.rows;
			if (this.transformRowFn) {
				rows = rows.map(this.transformRowFn);
			}
			if (res.data.res.totalCnt != null) {
				this.totalCnt = res.data.res.totalCnt;
			}

			this.loadTime = res.headers['x-request-time'];

			if (!opts.isAppend) {
				if (this.localControl) {
					this.localShownCnt = this.portionSize || this.defaultPortionSize;
				}
				this.rows = [];
			}

			this.res = res.data.res;

			if (!rows.length) {
				this.isNoMore = true;
				return;
			}

			this.rows = this.rows.concat(rows);
		},
		async tryLoadStat() {
			if (!this.remoteStatMethod || this.localControl) return;

			this.statList = null;
			this.totalCnt = -1;

			let requestNumber = ++this.lastGetStatRequest;

			this.stat = null;
			let stat = await this.get(this.remoteStatMethod, this.params);
			if(requestNumber !== this.lastGetStatRequest) return;
			this.totalCnt = stat.totalCnt;

			if (this.statFn) {
				this.statList = this.statFn(stat);
			}

			this.stat = stat;
		},
		async exportFile() {
			let data;
			if (this.beforeExportFn) {
				data = await this.beforeExportFn();
				if (!data) return;
			}
			this.isExporting = true;
			await this.download(this.exportMethod, this.params, data);
			this.isExporting = false;
		},
		getFieldText(field) {
			return this.fieldMap && this.fieldMap[field] || field;
		},
		isTimestamp(val) {
			if (isNaN(val)) return false;
			return (val > 1300000000 && val < 1600000000);
		},
		reload(extraData) {
			this.getRows({extraData});
		},
		onScrollToBottom() {
			if (this.localControl) {
				this.localShownCnt += this.portionSize || this.defaultPortionSize;
			} else {
				this.getRows({isAppend: true});
			}
		},
		refreshReloadParam() {
			let query = {...this.params};

			if (this.reloadIgnoreFields) {
				this.reloadIgnoreFields.split(',').forEach(field => {
					delete query[field];
				});
			}

			this.reloadParam = JSON.stringify(query);
		},
		onScroll() {
			if (this.initPortionSize) return;

			let OFFSET = 200;

			let scrollBottom = this.getScrollTop() + this.getWinHeight();
			let docHeight = document.body.scrollHeight;

			if (scrollBottom > docHeight - OFFSET) {
				this.onScrollToBottom();
			}
		},
		getScrollTop() {
			return window.scrollY != null ? window.scrollY : window.pageYOffset;
		},
		getWinHeight() {
			return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
		}
	},
	watch: {
		params() {
			if (this.localControl) {
				this.localShownCnt = this.portionSize || this.defaultPortionSize;
			} else {
				this.refreshReloadParam();
			}
		},
		reloadParam() {
			this.getRows();
		}
	},
	async mounted() {
		this.refreshReloadParam();
		window.addEventListener('scroll', this.onScroll);
	},
	destroyed() {
		window.removeEventListener('scroll', this.onScroll);
	}
};
