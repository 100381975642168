<template>
	<div v-if="!tour">Загрузка</div>
	<div v-else class="tour-box">
		<span class="close-btn" @click="close">
			<i class="fa fa-remove"></i>
			Закрыть
		</span>
		<div>
			<div>Категории:</div>
			<div>
				<multi-sel :items="categoryItems" v-model="tour.cats"></multi-sel>
			</div>
		</div>
		<div>
			<article-editor v-model="tour.markdownRus"></article-editor>
		</div>
		<div>
			<button @click="onSaveClick">Сохранить</button>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['tourId'],
		data() {
			return {
				tour: null,
				categoryItems: null
			};
		},
		methods: {
			async loadTour() {
				this.tour = await this.get('Tour.getTour', [this.tourId]);
			},
			onSaveClick() {
				this.post('Tour.saveTour', [this.tour]);
			},
			close() {
				this.$emit('close');
			},
			async loadCategories() {
				let cats = await this.get('Tour.getCats');
				this.categoryItems = cats.map(cat => {
					return {key: cat.slug, val: cat.name};
				});
			}
		},
		mounted() {
			this.loadTour();
			this.loadCategories();
		}
	}
</script>

<style lang="less">
	.tour-box {
		position: relative;

		.close-btn {
			position: absolute;
			right: 5px;
			top: 5px;
			cursor: pointer;
		}
	}
</style>