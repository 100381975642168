import axios from 'axios';

export default {
	computed: {
		app() {
			return this.$root.$children[0];
		}
	},
	methods: {
		async get(classMethods, args = [], opts = {}) {
			let url = '/api/call/' + classMethods + '?argsJson=' + encodeURIComponent(JSON.stringify(args));
			let res = await axios(url);
			if (opts.full) return res;

			if (res.data.err) {
				throw new Error(res.data.err);
			}
			return res.data.res;
		},
		async post(classMethods, args = [], opts = {}) {
			let url = '/api/call/' + classMethods;
			let res = await axios.post(url, args);
			if (opts.full) return res;

			if (res.data.err) {
				throw new Error(res.data.err);
			}
			return res.data.res;
		},
		refreshQuery(opts = {}) {
			let query = this.app.query;
			for (let i in query) {
				if (!query.hasOwnProperty(i)) continue;
				if (query[i] === '' || query[i] == null) {
					delete query[i];
				}
			}

			let hash = opts.keepHash ? this.app.hash : null;

			this.navigate({ query, hash });
		},
		navigate(opts, isReplace = false) {
			let query = opts.query || {};

			let method = isReplace ? 'replace' : 'push';
			this.$router[method]({ ...opts, query }).catch(err => {
				if (err.name !== 'NavigationDuplicated') {
					throw err;
				}
			});
		},
		checkPermission(action) {
			if (!this.app.user) return null;
			let perms = this.app.user.permissions;
			return perms.indexOf(action) !== -1 || perms.indexOf('*') !== -1;
		}
	},
	directives: {
		'mousedown-outside': {
			bind(el, binding, vNode) {
				if (typeof binding.value !== 'function') {
					const compName = vNode.context.name;
					let warn = `[Vue-mousedown-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
					if (compName) {
						warn += `Found in component '${compName}'`;
					}

					console.warn(warn);
				}

				const bubble = binding.modifiers.bubble;
				const handler = (e) => {
					if (bubble || (!el.contains(e.target) && el !== e.target)) {
						binding.value(e);
					}
				};
				el.__vueMousedownOutside__ = handler;

				document.addEventListener('mousedown', handler);
			},

			unbind(el, binding) {
				document.removeEventListener('mousedown', el.__vueMousedownOutside__);
				el.__vueMousedownOutside__ = null;
			}
		},
		'block-scroll': {
			bind(el, binding, vNode) {
				let handler = e => {
					e.stopPropagation();

					if (el.scrollHeight <= el.offsetHeight) return;

					if (
						e.deltaY < 0 && el.scrollTop <= 0 ||
						e.deltaY > 0 && el.scrollTop + el.offsetHeight >= el.scrollHeight
					) {
						e.preventDefault();
					}
				};

				el.__vueMousewheelOutside__ = handler;

				el.addEventListener('wheel', handler);
			},
			unbind(el) {
				el.addEventListener('wheel', el.__vueMousewheelOutside__);
			}
		}
	}
};